import React, { useState } from "react";
import QrCodeValidationModal from "./QrCodeValidationModal";

const QrCodeWithValidation = () => {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <div>
      <button className="btn btn-primary" onClick={handleOpenModal}>
        Validate Google Authenticator
      </button>
      <QrCodeValidationModal show={showModal} handleClose={handleCloseModal} />
    </div>
  );
};

export default QrCodeWithValidation;
