import axios from 'axios';
import {  API_URL } from '../config/env';


const getToken = () => {
    const tokenHeader = localStorage.getItem('Header');
    const tokenPayload = localStorage.getItem('payload');
    const tokenSignature = localStorage.getItem('sig');

    return `${tokenHeader}.${tokenPayload}.${tokenSignature}`;
};

export const LoadRequestService = {

    getAllLoadRequest:(page) => {
        const token = getToken();
        return axios.get(`${API_URL}/admin/topup-request?page=${page}`, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },


    showLoadRequestById: (Id) => {
        const token = getToken();
        return axios.get(`${API_URL}/admin/topup-request/${Id}`, {
            headers: {
                Authorization: `Bearer ${token}`, // Set the Authorization header
            },
        });
    },
    approveLoadRequestById: (Id, data) => {
        const token = getToken();
        return axios.patch(`${API_URL}/admin/topup-request/status/${Id}`,data, {
            headers: {
                Authorization: `Bearer ${token}`, // Set the Authorization header
            },
        });
    },

    editLoadRequestById(id,loadData) {
        const token = getToken();
        return axios.patch(`${API_URL}/admin/topup-request/edit/${id}`, loadData, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },


    addLoadRequest: (data) => {
        const token = getToken();
        return axios.post(`${API_URL}/admin/topup-request/add`,data, {
            headers: {
                Authorization: `Bearer ${token}`, // Set the Authorization header
            },
        });
    },

    getAllLoadRequestPending:(page) => {
        const token = getToken();
        return axios.get(`${API_URL}/admin/topup-request/pending?page=${page}`, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },

    getAllLoadRequestSuccess:(page) => {
        const token = getToken();
        return axios.get(`${API_URL}/admin/topup-request/success?page=${page}`, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },

};