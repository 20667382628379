import axios from 'axios';
import {  API_URL } from '../config/env';

const getToken = () => {
    const tokenHeader = localStorage.getItem('Header');
    const tokenPayload = localStorage.getItem('payload');
    const tokenSignature = localStorage.getItem('sig');

    return `${tokenHeader}.${tokenPayload}.${tokenSignature}`;
};
export const WalletService = {

    getAllClientWallet: (page) => {
        const token = getToken();
        return axios.get(`${API_URL}/admin/client/wallet?page=${page}`, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },

    getAllAdminWallet:(page)=> {
        const token = getToken();
        return axios.get(`${API_URL}/admin/address/wallet?page=${page}`, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },


    showWalletAdminById: (Id) => {
        const token = getToken();
        return axios.get(`${API_URL}/admin/address/wallet/${Id}`, {
            headers: {
                Authorization: `Bearer ${token}`, // Set the Authorization header
            },
        });
    },
    
    showClientWalletById: (Id) => {
        const token = getToken();
        return axios.get(`${API_URL}/admin/client/wallet/${Id}`, {
            headers: {
                Authorization: `Bearer ${token}`, // Set the Authorization header
            },
        });
    },
    
    editWalletAdminById: (Id, walletData) => {
        const token = getToken();
        return axios.patch(`${API_URL}/admin/address/wallet/edit/${Id}`, walletData, {
            headers: {
                Authorization: `Bearer ${token}`, // Set the Authorization header
            },
        });
    },

    addWallet: (walletData) => {
        const token = getToken();
        return axios.post(`${API_URL}/admin/address/wallet/add`, walletData, {
            headers: {
                Authorization: `Bearer ${token}`, // Set the Authorization header
            },
        });
    },



    
    //depositrequest
    getDepositRequest: (page) => {
        const token = getToken();
        return axios.get(`${API_URL}/admin/request/deposit?page=${page}`, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },

    getDepositRequestbyId: (Id) => {
        const token = getToken();
        return axios.get(`${API_URL}/admin/request/deposit/${Id}`, {
            headers: {
                Authorization: `Bearer ${token}`, // Set the Authorization header
            },
        });
    },

    editRequestDeposit: (Id, requestData) => {
        const token = getToken();
        return axios.patch(`${API_URL}/admin/request/deposit/edit/${Id}`, requestData, {
            headers: {
                Authorization: `Bearer ${token}`, // Set the Authorization header
            },
        });
    },

    approveRequestDeposit: (Id, data) => {
        const token = getToken();
        return axios.patch(`${API_URL}/admin/request/deposit/approval/${Id}`, data, {
            headers: {
                Authorization: `Bearer ${token}`, // Set the Authorization header
            },
        });
    },


    //walletbalanceapi
    getMasterBalance(){
        const token = getToken();
        return axios.get(`${API_URL}/admin/wallet/balance`, {
            headers: {
                Authorization: `Bearer ${token}`, // Set the Authorization header
            },
        });
    },


    getAllLoad(){
        const token = getToken();
        return axios.get(`${API_URL}/admin/request/balance/master`, {
            headers: {
                Authorization: `Bearer ${token}`, // Set the Authorization header
            },
        });
    },

    getLoadBy(id){
        const token = getToken();
        return axios.get(`${API_URL}/admin/request/balance/master/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`, // Set the Authorization header
            },
        });
    },

    addLoad(data){
        const token = getToken();
        return axios.post(`${API_URL}/admin/request/balance/master/add `, data, {
            headers: {
                Authorization: `Bearer ${token}`, // Set the Authorization header
            },
        });
    },

    editLoad(data,id){
        const token = getToken();
        return axios.patch(`${API_URL}/admin/request/balance/master/edit/${id} `, data, {
            headers: {
                Authorization: `Bearer ${token}`, // Set the Authorization header
            },
        });
    },

    approveLoad(id, data){
        const token = getToken();
        return axios.patch(`${API_URL}/admin/request/balance/master/approval/${id} `, data, {
            headers: {
                Authorization: `Bearer ${token}`, // Set the Authorization header
            },
        });
    },



    // /admin/request/balance/master/
    // /admin/request/balance/master/{id}
    // /admin/request/balance/master/
    // /admin/request/balance/master/add 
    // body {
    //     "amountFiat":111,
    //     "amountCrypto":222,
    //     "fees":52,
    //     "transationHash":"zerfqsfqsfqd"
    // }



};