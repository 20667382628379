import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { checkAutoLogin } from '../../../../services/AuthService';
import { ReportService } from '../../../../services/ReportService';
import * as XLSX from 'xlsx';

const DepositReport = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [emailFilter, setEmailFilter] = useState("");
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [status, setStatus] = useState(null);

  useEffect(() => {
    checkAutoLogin(dispatch);
  }, [dispatch]);

  // Fetch all data on component mount
  useEffect(() => {
    fetchAllData();
  }, []);

  // Function to fetch all data
  const fetchAllData = () => {
    ReportService.getDepositWalletReport({})
      .then((response) => {
        const { data } = response.data; // Assuming `response.data` contains the array
        setData(data);
        setFilteredData(data);

      })
      .catch((error) => console.error('Error fetching data:', error));
  };


  const fetchFilteredData = (emailUser, startDate, endDate,status) => {
    ReportService.getDepositWalletReport({ emailUser, startDate, endDate,status })
      .then((response) => {
        const { data } = response.data; // Assuming `response.data` contains the array
        setData(data);
        setFilteredData(data);
      })
      .catch((error) => console.error('Error fetching filtered data:', error));
  };

  const handleFilter = () => {
    if (!emailFilter.trim() && !startDate && !endDate && !status) {
      fetchAllData(); // No filters applied
    } else if (emailFilter.trim() && startDate && endDate && status) {
      // Filter by email + start date + end date
      fetchFilteredData(emailFilter.trim(), startDate, endDate,status);
    } else if (startDate && endDate && status) {
      // Filter by start date + end date only
      fetchFilteredData(null, startDate, endDate,status);
    }  
    else if (startDate && endDate) {
      // Filter by start date + end date only
      fetchFilteredData(null, startDate, endDate,null);
    } 
    else if (emailFilter.trim() && status) {
      // Filter by start date + end date only
      fetchFilteredData(emailFilter.trim(), null, null,status);
    }
    else if (status) {
      // Filter by start date + end date only
      fetchFilteredData(null, null, null,status);
    }
    else if (emailFilter.trim()) {
      // Filter by email only
      fetchFilteredData(emailFilter.trim(), null, null,null);
    } else {
      console.warn('Invalid filter combination');
    }
  };
  // Handle filter input change
  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmailFilter(value);

    if (value.trim() === '') {
      // If input is cleared, fetch all data
      fetchAllData();
    } else {
      // Fetch filtered data based on the input
      fetchFilteredData(value);
    }
  };
  const handleStatusChange = (e) => {
    setStatus(e.target.value);
    // Add any additional filtering logic here if needed
};

  // Export filtered data to Excel
  const exportToExcel = () => {
    const exportData = filteredData.map((item) => ({
      'user': item.user || 'N/A',

      'amount': item.amount || 0,
      'currency': item.currency || 'N/A',
      'hashTransaction': item.hashTransaction || 'N/A',
      'status': item.status || 'N/A',
      'type':item.type || 'N/A',
      'uidAccount':item.uidAccount || 'N/A',
      'Created At': item.createdAt?.date || 'N/A',
    }));

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'User Report');

    XLSX.writeFile(workbook, 'deposit_Report.xlsx');
  };

  return (
    <div>
      <div className="page-titles form-head d-flex flex-wrap justify-content-between align-items-center mb-4">
        <h2 className="text-black font-w600 mb-0 me-auto mb-2 pr-3">Deposit Report</h2>
        <input
                type="text"
                value={emailFilter}
                onChange={handleEmailChange}
                className="form-control w-auto me-3"
                placeholder="Enter email to filter"
            />
        <select
            value={status}
            onChange={handleStatusChange}
            className="form-control w-auto me-3"
        >
            <option value="">All Statuses</option> {/* Default option */}
            <option value="Pending">Pending</option>
            <option value="Success">Success</option>
        </select>
        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          className="form-control w-auto me-3"
        />
        <input
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          className="form-control w-auto me-3"
        />
        <br/>
        <div>
        <button onClick={handleFilter} className="btn btn-primary btn-rounded me-3 mb-2">
          Apply Filters
        </button>
        <button
          onClick={exportToExcel}
          className="btn btn-primary btn-rounded me-3 mb-2"
        >
          <i className="las la-download scale5 me-3"></i>Download Report
        </button>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table mb-0 table-striped respon-table-data">
          <thead>
            <tr>
              <th>user</th>
              <th>amount</th>
              <th>type</th>
              <th>uidAccount</th>
              <th>status</th>
              <th>Created At</th>
              <th>hashTransaction</th>

            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => (
              <tr key={index}>
                <td>{item.user || 'N/A'}</td>
                <td>{item.amount || 'N/A'} {item.currency || 'N/A'} </td>
                <td>{item.type || 'N/A'}</td>
                <td>{item.uidAccount || 'N/A'}</td>
                <td>{item.status || 'N/A'}</td>
                <td>{item.createdAt?.date || 'N/A'}</td>
                <td>{item.hashTransaction || 'N/A'} </td>

              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DepositReport;
