import {format} from 'date-fns';
import { ColumnFilter } from './ColumnFilter';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import {secretKey} from '../../../config/env';

export const COLUMNS = [
	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'id',
		Filter: ColumnFilter,
		//disableFilters: true,
	},
	{
		Header : 'Name',
		Footer : 'Name',
		accessor: 'eventName',
		Filter: ColumnFilter,
	},
	{
		Header : 'Reward Number',
		Footer : 'Reward Number',
		accessor: 'rewardNumbers',
		Filter: ColumnFilter,
	},
	{
		Header: 'Status',
		Footer: 'Status',
		accessor: 'isActive',
		Filter: ColumnFilter,
		Cell: ({ value }) => (
			value ? (
				<span className="badge badge-success">Active</span>  // Adjust classes as needed
			) : (
				<span className="badge badge-danger">InActive</span>  // Adjust classes as needed
			)
		),
	},
	{
		Header : 'Created at',
		Footer : 'Created at',
		accessor: 'createdAt.date',
		Cell: ({ value }) => {return format(new Date(value), 'yyyy-MM-dd H:m:s')},
		Filter: ColumnFilter,
	},

    {
        Header: 'Action',
        Footer: 'Action',
        accessor: 'Id', // Assuming `id` is the unique identifier for the row
        Cell: ({ row }) => {
            const navigate = useNavigate();
            const [isModalOpen, setModalOpen] = useState(false);


            const handleShow = () => {
                navigate(`/${secretKey}/event/${row.original.id}`);
            };
            return (
                <div>
                    <button className="btn btn-primary" onClick={handleShow}>Show</button>
                </div>
            );
        },
        Filter: ColumnFilter,
    },

]



export const GROUPED_COLUMNS = [
	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'id'
	},
	{
		Header : 'Name',
		Footer : 'Name',
		columns: [
			{
				Header : 'First Name',
				Footer : 'First Name',
				accessor: 'first_name'
			},
			{
				Header : 'Last Name',
				Footer : 'Last Name',
				accessor: 'last_name'
			},
		]
	},
	{
		Header: 'Info',
		Footer: 'Info',
		columns: [
			{
				Header : 'Date of  Birth',
				Footer : 'Date of  Birth',
				accessor: 'date_of_birth'
			},
			{
				Header : 'Country',
				Footer : 'Country',
				accessor: 'country',
			},
			{
				Header : 'Phone',
				Footer : 'Phone',
				accessor: 'phone'
			},
		]
	},
]