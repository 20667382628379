import axios from 'axios';
import {  API_URL } from '../config/env';


const getToken = () => {
    const tokenHeader = localStorage.getItem('Header');
    const tokenPayload = localStorage.getItem('payload');
    const tokenSignature = localStorage.getItem('sig');

    return `${tokenHeader}.${tokenPayload}.${tokenSignature}`;
};
export const CardsService = {

    getAll: (page) => {
        const token = getToken();
        return axios.get(`${API_URL}/admin/card/all?page=${page}`, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },
    getById: (Id) => {
        const token = getToken();
        return axios.get(`${API_URL}/admin/card/${Id}`, {
            headers: {
                Authorization: `Bearer ${token}`, // Set the Authorization header
            },
        });
    },

    getTransactionById: (Id) => {
        const token = getToken();
        return axios.get(`${API_URL}/admin/card/transactions/${Id}`, {
            headers: {
                Authorization: `Bearer ${token}`, // Set the Authorization header
            },
        });
    },
    getBalancebyId: (Id) => {
        const token = getToken();
        return axios.get(`${API_URL}/admin/card/balance/${Id}`, {
            headers: {
                Authorization: `Bearer ${token}`, // Set the Authorization header
            },
        });
    },

    getMetricId: (Id) => {
        const token = getToken();
        return axios.get(`${API_URL}/admin/metric/user/card/${Id}`, {
            headers: {
                Authorization: `Bearer ${token}`, // Set the Authorization header
            },
        });
    },
}