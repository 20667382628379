import React, { useEffect, useState } from 'react';
import { CardRequestService } from '../../../services/CardRequestService';
import { Fragment } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './additcss.css';
import { format } from 'date-fns';
import { WalletService } from '../../../services/WalletService';
import {secretKey} from '../../../config/env';

const AdminWalletDetail = () => {
    const { Id } = useParams();
    const [walletData, setWalletData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (Id) {
            setLoading(true);
            WalletService.showWalletAdminById(Id)
                .then((response) => {
                    const { data } = response.data;
                    if (Array.isArray(data) && data.length > 0) {
                      setWalletData(data[0]); // Set the first item from the array
                    } else {
                        setError(new Error('No data found'));
                    }
                })
                .catch(error => {
                    console.error('Error fetching card data:', error);
                    setError(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            console.error('ID is undefined');
            setError(new Error('ID is undefined'));
            setLoading(false);
        }
    }, [Id]);


    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveClick = () => {
        const dataToSend = {
          value: walletData.value,
          isActive: walletData.isActive
        };
        setIsEditing(false);
        WalletService.editWalletAdminById(Id,dataToSend);
    };

    const handleCancelClick = () => {
        setIsEditing(false);
        // Optionally reset form data if necessary
    };

    const handleBack = () => {
      navigate(`/${secretKey}/admin-wallet`);
    };



    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;
    if (!walletData) return <p>No wallet data found.</p>;

    const formatDate = (date) => {
      if (!date) return ''; // Return an empty string if the date is null or undefined
      try {
          return format(new Date(date), 'yyyy-MM-dd'); // Try to format the date
      } catch (error) {
          console.error('Invalid date:', date, error);
          return ''; // Return an empty string if the date is invalid
      }
  };




    return (

        <Fragment>
          { walletData? (
          
        <div className="col-xl-12 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Admin Wallet detail</h4>
              <button className="btn btn-secondary" type="button" onClick={handleBack}>Back to list</button>

            </div>
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="row">
                    <div className="form-group mb-3 col-md-6">
                      <label>Id</label>
                      <input
                        className={`form-control ${!isEditing ? 'disabled-input' : ''}`}                        
                        type="text"
                        value={walletData.id}
                        disabled
                      />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label>Wallet Address</label>
                       <input
                        type="text"
                        className={`form-control ${!isEditing ? 'disabled-input' : ''}`} 
                        value={walletData.value}
                        disabled={!isEditing}
                        onChange={(e) => setWalletData({ ...walletData, value: e.target.value })}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label>status</label>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={walletData.isActive} // Use checked for checkboxes
                        disabled={!isEditing}
                        onChange={(e) =>
                          setWalletData({ ...walletData, isActive: e.target.checked })
                        } // Set value based on checkbox state
                      />
                    </div>
                    
                </div>
                {!isEditing ? (
                        <button className="btn btn-primary" type="button" onClick={handleEditClick}>Edit</button>
                    ) : (
                        <>
                            <button className="btn btn-primary" type="button" onClick={handleSaveClick}>Save</button>
                            <button className="btn btn-primary" type="button" onClick={handleCancelClick}>Cancel</button>
                        </>
                    )}
                </form>
              </div>
            </div>
          </div>
        </div>
          ):(<div className="">no data</div>)
        }
        </Fragment>
    );
};

export default AdminWalletDetail;
