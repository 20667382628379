import React from "react";

const StepTwo = ({
  RewardType,
  selectedRewardType,
  handleRewardSelect,
  rewardConditions,
  handleRewardAmountChange,
  selectedReward,
  handleCardCreationChange,
  selectedActivities,
  cardCreation
}) => {
  return (
    <section>
      <h4>Reward</h4>
      <label htmlFor="RewardSelect">Select Reward Type</label>
      <select
        className="form-control"
        id="RewardSelect"
        onChange={handleRewardSelect}
        value={selectedReward} // Bind the value to the selectedReward state
      >
        <option value="">-- Select a Reward --</option>
        {RewardType.map((reward) => (
          <option key={reward.id} value={reward.id}>
            {reward.Name}
          </option>
        ))}
      </select>
      <div className="form-group mt-2">
        <label htmlFor="rewardAmount">Reward Amount</label>
        <input
          type="number"
          className="form-control"
          id="rewardAmount"
          name="rewardAmount"
          value={rewardConditions.Reward}
          onChange={(e) => handleRewardAmountChange("Reward", e.target.value)}
        />
      </div>

      {/* Display selected activities as tags */}
      

      {/* Conditionally show input fields based on selected activities */}
      {(selectedActivities.includes("2") || selectedActivities.includes("3")) && <h4>Reward Conditions</h4>}
      {selectedActivities.includes("2") && (
        <div className="form-group mt-2">
          <label htmlFor="depositAmount">Deposit Amount</label>
          <input
            type="number"
            className="form-control"
            id="depositAmount"
            name="depositAmount"
            value={rewardConditions.Deposit}
            onChange={(e) =>
              handleRewardAmountChange("Deposit", e.target.value)
            }
          />
        </div>
      )}

      {selectedActivities.includes("3") && (
        <div className="form-group mt-2">
          <label htmlFor="topupAmount">Topup Amount</label>
          <input
            type="number"
            className="form-control"
            id="topupAmount"
            name="topupAmount"
            value={rewardConditions.Topup}
            onChange={(e) => handleRewardAmountChange("Topup", e.target.value)}
          />
        </div>
      )}
      {/* {selectedActivities.includes("4") && (
      
      <div className="form-group mt-2">
            <label>Card Creation</label>
            <div>
                <label>
                    <input
                        type="radio"
                        name="cardCreation"
                        value="1"
                        checked={cardCreation === 1}
                        defaultChecked
                        onChange={handleCardCreationChange}
                    />
                    Yes
                </label>
                <label style={{ marginLeft: "10px" }}>
                    <input
                        type="radio"
                        name="cardCreation"
                        value="0"
                        checked={cardCreation === 0}
                        onChange={handleCardCreationChange}
                    />
                    No
                </label>
            </div>
        </div>
      )} */}
    </section>
  );
};

export default StepTwo;
