import React, { useEffect, useState } from 'react';
import { CardRequestService } from '../../../services/CardRequestService';
import { Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import swal from "sweetalert";
import { WalletService } from '../../../services/WalletService';
import Widget from '../../pages/Widget';

const MasterWallet = () => {

    const [walletData, setWalletData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    const navigate=useNavigate();

    useEffect(() => {
            WalletService.getMasterBalance()
                .then((response) => {
                    const { data } = response;
                        setWalletData(data.balance); // Set the first item from the array
                })
                .catch(error => {
                    console.error('Error fetching card data:', error);
                    setError(error);
                })
    
    }, []);







    return (
    
        <Fragment>
                { walletData ? (
        <div className="col-xl-3 col-sm-6">
					<div className="card">
						<div className="card-body">
							<div className="">	
                                {walletData?.USD !== undefined ? (							
                               <b> Master Wallet Balance:<br/>{walletData.USD} USD</b>
                                ):(
                                    <p>not found</p>
                                )
                                }
							</div>
						</div>
					</div>
				</div>
                 ):(<div></div>)}
        </Fragment>
       
    );
};

export default MasterWallet;
