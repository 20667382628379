import React, { useState, Fragment } from 'react';
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import { WalletService } from '../../../services/WalletService';

const AddLoad = ({ isOpen, onClose }) => {
    const [formData, setFormData] = useState({
        amountFiat: "",
        amountCrypto: "",
        fees: "",
        transationHash: "",
        status: "",
        requestDateAt: "",
        approvalDate:""
    });

    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "requestDateAt") {
            // Format the selected date to "YYYY-MM-DD HH:mm:ss"
            const formattedDate = `${value} 00:00:00`;
            setFormData({ ...formData, [name]: formattedDate });
        } 
        else if (name === "approvalDate") {
            // Format the selected date to "YYYY-MM-DD HH:mm:ss"
            const formattedDate = `${value} 00:00:00`;
            setFormData({ ...formData, [name]: formattedDate });
        }
        else {
            setFormData({ ...formData, [name]: value });
        }
        // Clear error for the field when updated
        if (value.trim()) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: "",
            }));
        }
    };
    const validateForm = () => {
        const newErrors = {};
        // Required fields
        const requiredFields = [
            "amountCrypto",
            "transationHash",
            "status",
            "requestDateAt",
        ];

        requiredFields.forEach((field) => {
            if (!formData[field]?.trim()) {
                newErrors[field] = `${field.replace(/([A-Z])/g, " $1")} is required.`;
            }
        });

        return newErrors;
    };
    const handleSave = (e) => {
        e.preventDefault();

        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }else{
        WalletService.addLoad(formData)                 
        .then((response) => {
            swal("Good job!", "Top Up Added Successfully!", "success")
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        })
            .catch(error => {
            console.error('Error fetching card data:', error);
            swal("failed!", "Adding Top Up failed", "failed")
        })
        onClose();}
    };

    return (
        <Fragment>
            <Modal className="modal fade" show={isOpen} onHide={onClose}>
                <div className="modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Wallet Load</h5>
                            <button type="button" className="close" onClick={onClose}><span>&times;</span></button>
                        </div>
                        <div className="modal-body">
                            {/* Form for editing */}
                            <form>
                                <div className="form-group">
                                    <label htmlFor="amountFiat">Fiat Amount</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="amountFiat"
                                        name="amountFiat"
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="somvalueeField">Crypto Amount*</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="amountCrypto"
                                        name="amountCrypto"
                                        onChange={handleInputChange}
                                    />
                                             {errors.amountCrypto && (
                                        <span className="text-danger">{errors.amountCrypto}</span>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="someField">Fees</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="fees"
                                        name="fees"
                                        onChange={handleInputChange}
                                    />
                                </div> 
                                <div className="form-group">
                                    <label htmlFor="someField">Transaction Hash*</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="transationHash"
                                        name="transationHash"
                                        onChange={handleInputChange}
                                    />
                                            {errors.transationHash && (
                                    <span className="text-danger">{errors.transationHash}</span>
                                )}
                                </div>
                                <label htmlFor="RewardSelect">Select Status*</label>
                            <select
                                className="form-control"
                                id="status"
                                name="status"
                                onChange={handleInputChange}
                            >
                                <option value="">Select status</option>
                                <option value="Success">Success</option>
                                <option value="Pending">Pending</option>
                                <option value="Declined">Declined</option>
                            </select>
                            {errors.status && <span className="text-danger">{errors.status}</span>}
                            <div className="form-group">
                                <label>Approval Date</label>
                                <input
                                    type="date"
                                    id="approvalDate"
                                    name="approvalDate"
                                    className="form-control"
                                    onChange={handleInputChange}
                                />
                            </div>
                                                        {/* Start Date Input */}
                            <div className="form-group">
                                <label htmlFor="requestDateAt">Request Date *</label>
                                <input
                                type="date"
                                className="form-control"
                                id="requestDateAt"
                                name="requestDateAt"
                                onChange={handleInputChange}
                                />
                                          {errors.requestDateAt && (
                    <span className="text-danger">{errors.requestDateAt}</span>
                )}
                            </div>                            
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger light" onClick={onClose}>Close</button>
                            <button type="button" className="btn btn-primary" onClick={handleSave}>Save changes</button>
                        </div>
                    </div>
                </div>
            </Modal>
        </Fragment>
    );
};

export default AddLoad;