import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { checkAutoLogin } from '../../../../services/AuthService';
import { ReportService } from '../../../../services/ReportService';
import * as XLSX from 'xlsx';
import {secretKey} from '../../../../config/env';

const TransactionReport = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [status, setStatus] = useState(null);
  const [transactionType, setTransactionType] = useState(null);
  const [transactionMode, setTransactionMode] = useState(null);
  const navigate = useNavigate();

  const { Id } = useParams();


  useEffect(() => {
    checkAutoLogin(dispatch);
  }, [dispatch]);

  // Fetch all data on component mount
  useEffect(() => {
    fetchAllData();
  }, []);

  // Function to fetch all data
  const fetchAllData = () => {
    ReportService.getTransactionReport({},Id)
      .then((response) => {
        const { data } = response.data; // Assuming `response.data` contains the array
        setData(data);
        setFilteredData(data);

      })
      .catch((error) => console.error('Error fetching data:', error));
  };


  const fetchFilteredData = (startDate, endDate,transactionType,transactionMode,status) => {
    ReportService.getTransactionReport({ startDate, endDate,transactionType,transactionMode,status },Id)
      .then((response) => {
        const { data } = response.data; // Assuming `response.data` contains the array
        setData(data);
        setFilteredData(data);
      })
      .catch((error) => console.error('Error fetching filtered data:', error));
  };

  const handleFilter = () => {
    if (!status && !startDate && !endDate && !transactionMode && !transactionType) {
      fetchAllData(); // No filters applied
    } else {
      fetchFilteredData(startDate, endDate,transactionType,transactionMode,status);
    }
  };
  // Handle filter input change
  const handleStatusChange = (e) => {
    setStatus(e.target.value);
    // Add any additional filtering logic here if needed
};
const handleTransactionTypeChange = (e) => {
  setTransactionType(e.target.value);
  // Add any additional filtering logic here if needed
};
const handleModeChange = (e) => {
  setTransactionMode(e.target.value);
  // Add any additional filtering logic here if needed
};


  // Export filtered data to Excel
  const exportToExcel = () => {
    const exportData = filteredData.map((item) => ({

      'authAmount': item.authAmount || 0,
      'authCurrencyCode': item.authCurrencyCode || 0,
      'dateOfTransaction': item.dateOfTransaction || "N/A",

      'description':item.description || 'N/A',
      'localDescription':item.localDescription || 'N/A',

      'mcc': item.mcc || 'N/A',
      'merchantCurrency': item.merchantCurrency || 'N/A',
      'merchantName': item.merchantName || 'N/A',
      'retrievalReferenceNumber': item.retrievalReferenceNumber || 'N/A',
     
      'settlementDate': item.settlementDate || 'N/A',
      'sourceAmount': item.sourceAmount || 0,

     
      'status': item.status || "null",
      'transactionCountry': item.transactionCountry || 'N/A',

      'transactionId': item.transactionId || 'N/A',
      'transactionMode': item.transactionMode || 'N/A',
      'transactionResponse': item.transactionResponse || 'N/A',
      'transactionType': item.transactionType || 'N/A',
      'transactionUuid': item.transactionUuid || 'N/A',

    }));

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'User Report');

    XLSX.writeFile(workbook, 'Transactions_Report.xlsx');
  };
  const handleBackToList = () => {
    navigate(`/${secretKey}/transaction-report`); // Replace with the path to your list view
  };
  return (
    <div>
      <div className="page-titles form-head d-flex flex-wrap justify-content-between align-items-center mb-4">
        <h2 className="text-black font-w600 mb-0 me-auto mb-2 pr-3">Transactions Report</h2>

      </div>
      <div className='m-lg-5 row'>
        from: 
        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          className="form-control w-auto me-3"
        />
        To: 
        <input
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          className="form-control w-auto me-3"
        />
         <select
            value={status}
            onChange={handleStatusChange}
            className="form-control w-auto me-3"
        >
            <option value="">All Statuses</option> {/* Default option */}
            <option value="1">COMPLETION</option>
            <option value="2">NULL</option>
        </select>
        <select
            value={transactionType}
            onChange={handleTransactionTypeChange}
            className="form-control w-auto me-3"
        >
            <option value="">Transaction Type</option> {/* Default option */}
            <option value="Card_Load">Card_Load</option>
            <option value="authorization_clearing">authorization clearing</option>
            <option value="authorization_reversal">authorization reversal</option>
            <option value="Balance_Adjustment">Balance Adjustment</option>

        </select>
        <select
            value={transactionMode}
            onChange={handleModeChange}
            className="form-control w-auto me-3"
        >
            <option value="">Transaction Mode</option> {/* Default option */}
            <option value="Credit">Credit</option>
            <option value="Debit">Debit</option>
        </select>
        </div>
        <div>

        
        <button onClick={handleFilter} className="btn btn-primary btn-rounded me-3 mb-2">
          Apply Filters
        </button>
        <button
          onClick={exportToExcel}
          className="btn btn-primary btn-rounded me-3 mb-2"
        >
          <i className="las la-download scale5 me-3"></i>Download Report
        </button>
        <button className='btn btn-secondary ms-2' onClick={handleBackToList}>
                            Back to List
                        </button>
        </div>
      <div className="table-responsive">
        <table className="table mb-0 table-striped respon-table-data">
          <thead>
            <tr>
              <th>authAmount</th>
              <th>authCurrencyCode</th>
              <th>dateOfTransaction</th>

              <th>description</th>
              <th>localDescription</th>

              <th>mcc</th>
              <th>merchantCurrency</th>
              <th>merchantName</th>

              <th>retrievalReferenceNumber</th>
              <th>settlementDate</th>
              <th>sourceAmount</th>
              <th>status</th>
              <th>transactionCountry</th>

              <th>transactionId</th>
              <th>transactionMode</th>
              <th>transactionResponse</th>
              <th>transactionType</th>
              <th>transactionUuid</th>




            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => (
              <tr key={index}>
                <td>{item.authAmount || 'N/A'}</td>
                <td>{item.authCurrencyCode || 'N/A'}</td>
                <td>{item.dateOfTransaction || 'N/A'} </td>
                <td>{item.description || 'N/A'}</td>
                <td>{item.localDescription || 'N/A'}</td>

                <td>{item.mcc || 'N/A'} </td>
                <td>{item.merchantCurrency || 'N/A'} </td>
                <td>{item.merchantName || 'N/A'} </td>
                <td>{item.retrievalReferenceNumber || 'N/A'}</td>
                <td>{item.settlementDate || 'N/A'}</td>

                <td>{item.sourceAmount || 'N/A'} </td>
                <td>{item.status || 'N/A'} </td>
                <td>{item.transactionCountry || 'N/A'} </td>
                <td>{item.transactionId || 'N/A'}</td>
                <td>{item.transactionMode || 'N/A'}</td>

                <td>{item.transactionResponse || 'N/A'} </td>
                <td>{item.transactionType || 'N/A'} </td>
                <td>{item.transactionUuid || 'N/A'} </td>

              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TransactionReport;
