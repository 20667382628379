import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import logoExpanded from "../../../images/logo_paytaps.png";
import logoCollapsed from "../../../images/logo192.png";

export function NavMenuToggle(isMenuOpen) {
  let mainwrapper = document.querySelector("#main-wrapper");
  if (isMenuOpen) {
    mainwrapper.classList.add("menu-toggle");
  } else {
    mainwrapper.classList.remove("menu-toggle");
  }
}

const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  const navRef = useRef(null);

  // Function to close menu when clicked outside
  const handleClickOutside = (event) => {
    if (navRef.current && !navRef.current.contains(event.target)) {
      setToggle(false); // Close the menu
      NavMenuToggle(false); // Ensure menu state reflects closure
    }
  };

  // Add event listener to detect clicks outside
  useEffect(() => {
    if (toggle) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    // Cleanup the event listener on component unmount or toggle state change
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [toggle]);

  return (
    <div className="nav-header" ref={navRef}>
      <Link
        to="/"
        className="brand-logo"
        
        onClick={(event) => {
          event.preventDefault(); // Prevent the default redirection
          setToggle(!toggle);
          NavMenuToggle(!toggle); // Pass the new toggle state
        }}
      >
        {/* Toggle between logos based on the collapsed state */}
        <img
          className="brand-title"
          src={toggle ? logoCollapsed : logoExpanded}
          alt="Logo"
          style={{
            transition: "0.3s ease", // Smooth transition
            maxWidth: toggle ? "45px" : "100%", // Set max-width when expanded
          }}
        />
      </Link>
    </div>
  );
};

export default NavHader;
