import {format} from 'date-fns';
import { ColumnFilter } from './ColumnFilter';
import { useNavigate } from 'react-router-dom';
import {secretKey} from '../../../config/env';




export const COLUMNS = [

	
	{
		Header : 'Amount',
		Footer : 'Amount',
		accessor: 'authAmount',
		Filter: ColumnFilter,
	},
	{
		Header : 'Currency',
		Footer : 'Currency',
		accessor: 'authCurrencyCode',
		Filter: ColumnFilter,
	},
	{
		Header : 'Merchant Currency',
		Footer : 'Merchant Currency',
		accessor: 'merchantCurrency',
		Filter: ColumnFilter,
	},
	{
		Header : 'Merchant Name',
		Footer : 'Merchant Name',
		accessor: 'merchantName',
		Filter: ColumnFilter,
		Cell: ({ value }) => value || 'Unknown', // Display "Unknown" if the value is falsy

	},
	{
		Header : 'Reference Number',
		Footer : 'Reference Number',
		accessor: 'retrievalReferenceNumber',
		Filter: ColumnFilter,
	},

	{
		Header : 'transaction type',
		Footer : 'transaction type',
		accessor: 'transactionType',
		Filter: ColumnFilter,
	},
	{
		Header : 'transaction Uuid',
		Footer : 'transaction Uuid',
		accessor: 'transactionUuid',
		Filter: ColumnFilter,
	},
	{
		Header : 'transaction mode',
		Footer : 'transaction mode',
		accessor: 'transactionMode',
		Filter: ColumnFilter,
	},
	{
		Header : 'transaction Id',
		Footer : 'transaction Id',
		accessor: 'transactionId',
		Filter: ColumnFilter,
		//disableFilters: true,
	},
	{
		Header : 'Status',
		Footer : 'Status',
		accessor: 'status',
		Filter: ColumnFilter,
		Cell: ({ value }) => {
			let badgeClass = '';
			switch (value) {
				case 'Pending':
					badgeClass = 'badge-warning'; // Customize as needed
					break;
				case 'Success':
					badgeClass = 'badge-success'; // Customize as needed
					break;
				case 'Declined':
					badgeClass = 'badge-danger'; // Customize as needed
					break;
				default:
					badgeClass = 'badge-secondary'; // Default badge
			}
			return (
				<span className={`badge ${badgeClass}`}>
					{value || "Unknown"} 
				</span>
			);
		},
	},
	{
		Header: 'date of transaction',
		Footer: 'date of transaction',
		accessor: row => row.dateOfTransaction, // Direct access to raw date value
		Cell: ({ value }) => { 
			return format(new Date(value), 'yyyy-MM-dd H:m:s');
		},
		Filter: ColumnFilter,
		sortType: (rowA, rowB) => {
			// Sorting by latest date first (most recent)
			const dateA = new Date(rowA.original.createdAt.date);
			const dateB = new Date(rowB.original.createdAt.date);
			return dateB - dateA; // Sort by latest date first
		},
	},
	

]



export const GROUPED_COLUMNS = [
	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'id'
	},
	{
		Header : 'Name',
		Footer : 'Name',
		columns: [
			{
				Header : 'First Name',
				Footer : 'First Name',
				accessor: 'first_name'
			},
			{
				Header : 'Last Name',
				Footer : 'Last Name',
				accessor: 'last_name'
			},
		]
	},
	{
		Header: 'Info',
		Footer: 'Info',
		columns: [
			{
				Header : 'Date of  Birth',
				Footer : 'Date of  Birth',
				accessor: 'date_of_birth'
			},
			{
				Header : 'Country',
				Footer : 'Country',
				accessor: 'country',
			},
			{
				Header : 'Phone',
				Footer : 'Phone',
				accessor: 'phone'
			},
		]
	},
]