import axios from 'axios';
import {  API_URL } from '../config/env';


const getToken = () => {
    const tokenHeader = localStorage.getItem('Header');
    const tokenPayload = localStorage.getItem('payload');
    const tokenSignature = localStorage.getItem('sig');

    return `${tokenHeader}.${tokenPayload}.${tokenSignature}`;
};
export const StatisticsService = {

    getUserMetric:() =>{
        const token = getToken();
        return axios.get(`${API_URL}/admin/metric/users`, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },

    getCardRequestMetric:() =>{
        const token = getToken();
        return axios.get(`${API_URL}/admin/metric/card_requests`, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },
    getDepositRequestMetric:() =>{
        const token = getToken();
        return axios.get(`${API_URL}/admin/metric/deposit_requests`, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },
    getTopupRequestMetric:() =>{
        const token = getToken();
        return axios.get(`${API_URL}/admin/metric/topup_requests`, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },
    getTransactionMetric:() =>{
        const token = getToken();
        return axios.get(`${API_URL}/admin/metric/transaction_overview`, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },
    getFeesTopupnMetric:() =>{
        const token = getToken();
        return axios.get(`${API_URL}/admin/metric/fee`, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },

    getCardMetric:(id) =>{
        const token = getToken();
        return axios.get(`${API_URL}/admin/metric/user/card/${id}`, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },
};