import React, { useMemo, useState, useEffect } from 'react';
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import { COLUMNS } from './Columns';
import { GlobalFilter } from './GlobalFilter'; 
import './filtering.css';
import { Fragment } from 'react';
import { LoadRequestService } from '../../../services/LoadRequestService';
import MasterWallet from '../MasterWalletBalance/MasterWallet';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { checkAutoLogin } from '../../../services/AuthService';


import jsPDF from 'jspdf';
import 'jspdf-autotable'; 

export const PendingLoadRequests = () => {
    const columns = useMemo(() => COLUMNS, []);
    const [data, setData] = useState([]); // State to store the fetched data
    const [currentPage, setCurrentPage] = useState(0); // Current page number (0-based index)
    const [totalPages, setTotalPages] = useState(0); // Total number of pages
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [count, setCount] = useState([]);

    useEffect(() => {
        checkAutoLogin(dispatch, navigate);
    }, [dispatch, navigate]);

    // Fetch the card data using the card service
    useEffect(() => {
        // You can modify the service method to accept page number and items per page
        LoadRequestService.getAllLoadRequestPending(currentPage+1) // API expects 1-based index, so add 1
            .then((response) => {
                const { data } = response.data;
                const pendingRequests = data.items.filter(item => item.status === 'Pending'); 

                setData(pendingRequests); // Set the data received from API
                // Calculate total pages
                setCount(data.totalCount); 
                const calculatedTotalPages = Math.ceil(data.totalCount / data.numItemsPerPage);
                setCurrentPage(data.currentPageNumber - 1); // API currentPageNumber is 1-based, adjust for 0-based
                setTotalPages(calculatedTotalPages);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, [currentPage]); // Re-fetch data when currentPage changes

    const tableInstance = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: currentPage },
            manualPagination: true, // Tell react-table that pagination is handled manually
            pageCount: totalPages,  // Pass the total number of pages
        },
        useFilters,
        useGlobalFilter,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        page,
        canNextPage,
        canPreviousPage,
        setGlobalFilter,
    } = tableInstance;

    const { globalFilter, pageIndex } = state;

    // Pagination control functions
    const handleNextPage = () => {
        if (canNextPage) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (canPreviousPage) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    };

    const handleGotoPage = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const exportToPDFCurrent = () => {
        const doc = new jsPDF();
        const tableColumn = columns.map(column => column.Header); // Get the column headers
        const tableRows = data.map(row => 
            columns.map(column => {
                if (column.accessor === 'user.email') {
                    return row.user ? row.user.email : ''; // Safely access nested email
                }
                if (typeof column.accessor === 'function') {
                    return column.accessor(row); // Handle accessor functions
                }
                return row[column.accessor];
            })
        );        
        
        doc.autoTable({
            head: [tableColumn],
            body: tableRows,
        });
    
        doc.save('Top_Up_Request_List.pdf');
    };
        // Function to fetch all pages of data
        const fetchAllData = async () => {
            let allData = [];
            let page = 1;
    
            while (page <= totalPages) {
                try {
                    const response = await LoadRequestService.getAllLoadRequest(page); // Adjust service call for each page
                    const { items } = response.data.data; // Adjust based on response structure
                    allData = [...allData, ...items];
                    page++;
                } catch (error) {
                    console.error('Error fetching data:', error);
                    break;
                }
            }
            return allData;
        };
    
        // Function to export all data to PDF
        const exportToPDF = async () => {
            const allData = await fetchAllData(); // Fetch all data
            const doc = new jsPDF();
            const tableColumn = columns.map(column => column.Header); // Get the column headers
            const tableRows = allData.map(row => 
                columns.map(column => {
                    if (column.accessor === 'user.email') {
                        return row.user ? row.user.email : ''; // Safely access nested email
                    }
                    if (typeof column.accessor === 'function') {
                        return column.accessor(row); // Handle accessor functions
                    }
                    return row[column.accessor];
                })
            );      
            doc.autoTable({
                head: [tableColumn],
                body: tableRows,
            });
    
            doc.save('All_Top_Up_Request_List.pdf');
        };
    return (
        <>
         
            { data? (
                   <Fragment>
			{/* <div className="form-head mb-4">
				<h2 className="text-black font-w600 mb-0">Load Requests</h2>
			</div> */}
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">Load Requests <br/><br/>
                    <h5>Total Requests: {count}</h5></h4>
                    <button className="btn btn-primary" onClick={exportToPDFCurrent}>Export current page</button>

                    <button className="btn btn-primary" onClick={exportToPDF}>Export All Data</button>

                    <MasterWallet/>

                </div>

                <div className="card-body">
                    <div className="table-responsive">
                        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
                        <table {...getTableProps()} className="table filtering-table table-responsive-lg">
                            <thead>
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <th {...column.getHeaderProps()}>
                                                {column.render('Header')}
                                                {column.canFilter ? column.render('Filter') : null}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()} className="">
                                {page.map((row) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map((cell) => {
                                                return <td {...cell.getCellProps()}> {cell.render('Cell')} </td>;
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <div className="d-flex justify-content-between">
                            <span>
                                Page{' '}
                                <strong>
                                    {pageIndex + 1} of {totalPages}
                                </strong>{''}
                            </span>
                            <span className="table-index">
                                Go to page : {' '}
                                <input
                                    type="number"
                                    className="ms-2"
                                    defaultValue={pageIndex + 1}
                                    onChange={(e) => {
                                        const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
                                        handleGotoPage(pageNumber);
                                    }}
                                />
                            </span>
                        </div>
                        <div className="text-center">
                            <div className="filter-pagination mt-3">
                                <button className="previous-button" onClick={() => handleGotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>
                                <button className="previous-button" onClick={handlePreviousPage} disabled={!canPreviousPage}>
                                    Previous
                                </button>
                                <button className="next-button" onClick={handleNextPage} disabled={!canNextPage}>
                                    Next
                                </button>
                                <button className="next-button" onClick={() => handleGotoPage(totalPages - 1)} disabled={!canNextPage}>{'>>'}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>			
            </Fragment>

            ):(<div>ffff</div>)
        }
        </>
    );
}

export default PendingLoadRequests;
