import React from "react";

const StepOne = ({
  handleInputChange,
  handleActivitySelect,
  activities,
  selectedActivities,
  rewardConditions,
  removeActivity,
  formData,
}) => {
  return (
    <section>
      {/* Event Name Input */}
      <div className="form-group">
        <label htmlFor="eventName">Event Name *</label>
        <input
          type="text"
          className="form-control"
          id="eventName"
          name="eventName"
          value={formData.eventName}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-group">
        <label htmlFor="eventAcronym">Event Acronym *</label>
        <input
          type="text"
          className="form-control"
          id="eventAcronym"
          name="eventAcronym"
          value={formData.eventAcronym}
          onChange={handleInputChange}
        />
      </div>

      {/* Start Date Input */}
      <div className="form-group">
        <label htmlFor="startDate">Start Date *</label>
        <input
          type="date"
          className="form-control"
          id="startDate"
          name="startDate"
          value={formData.startDate}
          onChange={handleInputChange}
          min={new Date().toISOString().split("T")[0]} // Disables past dates

        />
      </div>

      {/* End Date Input */}
      <div className="form-group">
        <label htmlFor="endDate">End Date *</label>
        <input
          type="date"
          className="form-control"
          id="endDate"
          name="endDate"
          value={formData.endDate}
          onChange={handleInputChange}
          min={formData.startDate || new Date().toISOString().split("T")[0]} // Ensures end date is not before start date

        />
          {formData.endDate && formData.startDate && formData.endDate <= formData.startDate && (
    <div className="text-danger">End date must be later than start date.</div>
  )}
        <div className="form-group mt-2">
          <label htmlFor="rewardNumber">Reward Number *</label>
          <input
            type="number"
            className="form-control"
            id="rewardNumber"
            name="rewardNumber"
            value={formData.rewardNumber}
            onChange={handleInputChange}
          />
        </div>
      </div>
      {/* Activity Selection */}
      <div className="form-group">
        <label htmlFor="activitySelect">Select Activity Type *</label>
        <select
          className="form-control"
          id="activitySelect"
          name="activitySelect"
          onChange={handleActivitySelect}
          value="" // Reset the dropdown after selection
        >
          <option value="">-- Select an activity --</option>
          {activities
            .filter(
              (activity) => !selectedActivities.includes(String(activity.id))
            ) // Exclude selected activities
            .map((activity) => (
              <option key={activity.id} value={activity.id}>
                {activity.activityName}
              </option>
            ))}
        </select>
        <div className="mt-3">
          {selectedActivities.length > 0 &&
            selectedActivities.map((activityId) => {
              const activity = activities.find(
                (a) => String(a.id) === String(activityId)
              );
              return activity ? (
                <span
                  key={activityId}
                  className="badge badge-primary mr-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => removeActivity(activityId)} // Remove tag on click
                >
                  {activity.activityName} &times;
                </span>
              ) : null;
            })}
        </div>
      </div>
    </section>
  );
};

export default StepOne;
