import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { checkAutoLogin } from '../../../../services/AuthService';
import { ReportService } from '../../../../services/ReportService';
import * as XLSX from 'xlsx';

const CardReport = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [emailFilter, setEmailFilter] = useState("");
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [status, setStatus] = useState(null);


  useEffect(() => {
    checkAutoLogin(dispatch);
  }, [dispatch]);

  // Fetch all data on component mount
  useEffect(() => {
    fetchAllData();
  }, []);

  // Function to fetch all data
  const fetchAllData = () => {
    ReportService.getCardTopupReport()
      .then((response) => {
        const { data } = response.data; // Assuming `response.data` contains the array
        setData(data);
        setFilteredData(data);

      })
      .catch((error) => console.error('Error fetching data:', error));
  };


  const fetchFilteredData = (emailUser, startDate, endDate,status) => {
    ReportService.getCardTopupReportByEmail({ emailUser, startDate, endDate ,status})
      .then((response) => {
        const { data } = response.data; // Assuming `response.data` contains the array
        setData(data);
        setFilteredData(data);
      })
      .catch((error) => console.error('Error fetching filtered data:', error));
  };

  const handleFilter = () => {
    if (!emailFilter.trim() && !startDate && !endDate && !status ) {
      fetchAllData(); // No filters applied
    } else {
      fetchFilteredData(emailFilter.trim(),startDate, endDate ,status);
    }
  };
  // Handle filter input change
  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmailFilter(value);

    if (value.trim() === '') {
      // If input is cleared, fetch all data
      fetchAllData();
    } else {
      // Fetch filtered data based on the input
      fetchFilteredData(value);
    }
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
    // Add any additional filtering logic here if needed
};
  // Export filtered data to Excel
  const exportToExcel = () => {
    const exportData = filteredData.map((item) => ({
      Email: item.user?.email  || 'N/A',
      'amountCryptoTotal': item.amountCryptoTotal || 'N/A',
      'amountFiat': item.amountFiat || 'N/A',
      'firstTopupCard': item.firstTopupCard || 'N/A',
      'paymentMethod': item.paymentMethod || 'N/A',
      'status':item.status || 'N/A',
      'topupFeeFiat':item.topupFeeFiat || 'N/A',
      'Created At': item.createdAt?.date || 'N/A',
    }));

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'User Report');

    XLSX.writeFile(workbook, 'topup_Report.xlsx');
  };

  return (
    <div>
      <div className="page-titles form-head d-flex flex-wrap justify-content-between align-items-center mb-4">
        <h2 className="text-black font-w600 mb-0 me-auto mb-2 pr-3">Card Top-up Report</h2>
        <input
                type="text"
                value={emailFilter}
                onChange={handleEmailChange}
                className="form-control w-auto me-3"
                placeholder="Enter email to filter"
            />
            <select
            value={status}
            onChange={handleStatusChange}
            className="form-control w-auto me-3"
           >
            <option value="">All Statuses</option> {/* Default option */}
            <option value="Success">Success</option>
            <option value="Pending">Pending</option>
        </select>
        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          className="form-control w-auto me-3"
        />
        <input
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          className="form-control w-auto me-3"
        />
        <button onClick={handleFilter} className="btn btn-primary btn-rounded me-3 mb-2">
          Apply Filters
        </button>
        <button
          onClick={exportToExcel}
          className="btn btn-primary btn-rounded me-3 mb-2"
        >
          <i className="las la-download scale5 me-3"></i>Download Report
        </button>
      </div>
      <div className="table-responsive">
        <table className="table mb-0 table-striped respon-table-data">
          <thead>
            <tr>
              <th>Email</th>
              <th>amountCryptoTotal</th>
              <th>amountFiat</th>
              <th>firstTopupCard</th>
              <th>paymentMethod</th>
              <th>status</th>
              <th>topupFeeFiat</th>

              <th>Created At</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => (
              <tr key={index}>
                <td>{item.user?.email || 'N/A'}</td>
                <td>{item.amountCryptoTotal || 'N/A'} USDT</td>
                <td>{item.amountFiat || 'N/A'} USD</td>



                <td>{item.firstTopupCard || 'N/A'}</td>
                <td>{item.paymentMethod || 'N/A'}</td>
                <td>{item.status || 'N/A'}</td>
                <td>{item.topupFeeFiat || 'N/A'}</td>

                <td>
                    {item.createdAt?.date
                        ? new Date(item.createdAt.date).toISOString().slice(0, 19).replace('T', ' ')
                        : 'N/A'}
                </td>             
                 </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CardReport;
