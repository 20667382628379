import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { checkAutoLogin } from '../../../../services/AuthService';
import { ReportService } from '../../../../services/ReportService';
import * as XLSX from 'xlsx';

const UserReport = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [emailFilter, setEmailFilter] = useState("");
  const [isCard, setIsCard] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    checkAutoLogin(dispatch);
  }, [dispatch]);

  // Fetch all data on component mount
  useEffect(() => {
    fetchAllData();
  }, []);

  // Function to fetch all data
  const fetchAllData = () => {
    ReportService.getUsersReport({})
      .then((response) => {
        const { data } = response.data; // Assuming `response.data` contains the array
        setData(data);
        setFilteredData(data);


      })
      .catch((error) => console.error('Error fetching data:', error));
  };

  // Function to fetch filtered data
  const fetchFilteredData = (selectUser, isCard) => {
    ReportService.getUsersReport({selectUser, isCard})
      .then((response) => {
        const { data } = response.data; // Assuming `response.data` contains the array
        setData(data);
        setFilteredData(data);   
        console.log(data);
        console.log(filteredData);   
      })
      .catch((error) => console.error('Error fetching filtered data:', error));
  };

  // Handle filter input change
  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmailFilter(value);

    if (value.trim() === '') {
      fetchAllData();
    } else {
      fetchFilteredData(value);
    }
  };
  const handleFilter = () => {

    if (!emailFilter.trim()  && !isCard) {
      fetchAllData(); // No filters applied
    } else {

      fetchFilteredData(emailFilter.trim(),isCard);
    }

  };

  // Export filtered data to Excel
  const exportToExcel = () => {
    const exportData = filteredData.map((item) => ({
      Name: item.card?.fullName || 'N/A',
      Email: item.userEmail || 'N/A',
      'Active Month': item.card?.activeMonth || 'N/A',
      'Card Status': item.card.uniqueUserID ? 'Yes' : 'No',
      'Card Order Fees': item.card?.cardOrderFees || 'N/A',
      'Sign-in Rates':item.SigninRates || 0,

      'Created At': item.createdAt?.date || 'N/A',
    }));

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'User Report');

    XLSX.writeFile(workbook, 'User_Report.xlsx');
  };
  const handleStatusChange = (e) => {
    setIsCard(e.target.value);
    // Add any additional filtering logic here if needed
};
  return (
    <div>
      <div className="page-titles form-head d-flex flex-wrap justify-content-between align-items-center mb-4">
        <h2 className="text-black font-w600 mb-0 me-auto mb-2 pr-3">User Report</h2>
        <input
                type="text"
                value={emailFilter}
                onChange={handleEmailChange}
                className="form-control w-auto me-3"
                placeholder="Enter email to filter"
            />
        <select
            value={isCard}
            onChange={handleStatusChange}
            className="form-control w-auto me-3"
        >
            <option value="">All Statuses</option> {/* Default option */}
            <option value="true">Has Card</option>
            <option value="false">No Card</option>
        </select>
        <button onClick={handleFilter} className="btn btn-primary btn-rounded me-3 mb-2">
          Apply Filters
        </button>
        <button
          onClick={exportToExcel}
          className="btn btn-primary btn-rounded me-3 mb-2"
        >
          <i className="las la-download scale5 me-3"></i>Download Report
        </button>
      </div>
      <div className="table-responsive">
        <table className="table mb-0 table-striped respon-table-data">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Active Month</th>
              <th>Card Status</th>
              <th>SigninRates</th>
              <th>Created At</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => (
              <tr key={index}>
                <td>{item.card?.fullName || 'N/A'}</td>
                <td>{item.userEmail || 'N/A'}</td>
                <td>{item.card?.activeMonth || 'N/A'}</td>
                <td>{item.card.uniqueUserID  ? 'Yes' : 'No'}</td>
                <td>{item.SigninRates || 0}</td>

                <td>
                    {item.createdAt?.date
                        ? new Date(item.createdAt.date).toISOString().slice(0, 19).replace('T', ' ')
                        : 'N/A'}
                </td>                   
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserReport;
