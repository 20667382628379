import React, { useMemo, useState, useEffect } from 'react';
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import { COLUMNS } from './Columns';
import { GlobalFilter } from './GlobalFilter'; 
import './filtering.css';
import { Fragment } from 'react';
import { LoadRequestService } from '../../../services/LoadRequestService';
import MasterWallet from '../MasterWalletBalance/MasterWallet';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { checkAutoLogin } from '../../../services/AuthService';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import jsPDF from 'jspdf';
import 'jspdf-autotable'; 
import { CardsService } from '../../../services/CardsService';
import {secretKey} from '../../../config/env';

export const TransactionDetail = () => {
  const { Id } = useParams();
    const columns = useMemo(() => COLUMNS, []);
    const [data, setData] = useState([]); // State to store the fetched data
    const [currentPage, setCurrentPage] = useState(0); // Current page number (0-based index)
    const [totalPages, setTotalPages] = useState(0); // Total number of pages
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [count, setCount] = useState([]);
    const [balance, setBalance] = useState([]);

    useEffect(() => {
        checkAutoLogin(dispatch, navigate);
    }, [dispatch, navigate]);

    // Fetch the card data using the card service
    useEffect(() => {
        // You can modify the service method to accept page number and items per page
        CardsService.getTransactionById(Id) // API expects 1-based index, so add 1
            .then((response) => {
                const { data, currentPageNumber, numItemsPerPage, totalCount } = response.data;
                
                // Calculate total pages
                console.log(data);
                setData(data);
                setCount(data.length); 
                const calculatedTotalPages = Math.ceil(data.length / data.numItemsPerPage);
                setCurrentPage(data.currentPageNumber - 1); // API currentPageNumber is 1-based, adjust for 0-based
                setTotalPages(calculatedTotalPages);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, [currentPage]); // Re-fetch data when currentPage changes

    useEffect(() => {
        // You can modify the service method to accept page number and items per page
        CardsService.getBalancebyId(Id) // API expects 1-based index, so add 1
            .then((response) => {
                const { data, currentPageNumber, numItemsPerPage, totalCount } = response.data;
                
                // Calculate total pages
                console.log(data);
                setBalance(data.balance);
                setCount(data.length); 
                const calculatedTotalPages = Math.ceil(data.length / data.numItemsPerPage);
                setCurrentPage(data.currentPageNumber - 1); // API currentPageNumber is 1-based, adjust for 0-based
                setTotalPages(calculatedTotalPages);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, [currentPage]); // Re-


    const tableInstance = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: currentPage },
            manualPagination: true, // Tell react-table that pagination is handled manually
            pageCount: totalPages,  // Pass the total number of pages
        },
        useFilters,
        useGlobalFilter,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        page,
        canNextPage,
        canPreviousPage,
        setGlobalFilter,
    } = tableInstance;

    const { globalFilter, pageIndex } = state;

    // Pagination control functions
    const handleNextPage = () => {
        if (canNextPage) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (canPreviousPage) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    };

    const handleGotoPage = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const fetchAllData = async () => {
      try {
        const response = await CardsService.getTransactionById(Id); // Fetch data for the given ID
        const { items } = response.data; // Extract items from the response
    
        return items; // Return all data directly
      } catch (error) {
        console.error('Error fetching data:', error);
        return []; // Return an empty array in case of an error
      }
    };
    
    const exportToExcel = async () => {
      const allData = await fetchAllData(); // Fetch all data
      console.log(allData);
      // Map data based on the columns and group them if needed
      const tableRows = data.map(row => {
        return COLUMNS.reduce((acc, column) => {
          let value;
    
          // Handle nested accessors like 'user.email'
          if (typeof column.accessor === 'string') {
            const keys = column.accessor.split('.');
            value = keys.reduce((obj, key) => (obj ? obj[key] : ''), row);
          } else if (typeof column.accessor === 'function') {
            // Handle accessor functions
            value = column.accessor(row);
          } else {
            // Direct accessor
            value = row[column.accessor] || ''; // Return an empty string if the value is undefined
          }
    
          return { ...acc, [column.Header]: value }; // Use column headers as keys
        }, {});
      });
    
      // Handle grouped columns (flatten them for export)
      const flattenGroupedColumns = (columns, parentHeader = '') => {
        return columns.reduce((acc, column) => {
          if (column.columns) {
            // Recursively process nested columns
            acc = [...acc, ...flattenGroupedColumns(column.columns, column.Header)];
          } else {
            // Use parent header and column header combined for grouped columns
            acc.push({
              Header: parentHeader ? `${parentHeader} - ${column.Header}` : column.Header,
              accessor: column.accessor,
            });
          }
          return acc;
        }, []);
      };
    
      const flattenedColumns = flattenGroupedColumns(COLUMNS);
    
      // Convert data to a worksheet with the new flattened columns
      const worksheet = XLSX.utils.json_to_sheet(tableRows, {
        header: flattenedColumns.map(col => col.Header), // Ensure headers are correct
      });
    
      // Create a workbook and add the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'All_Transactions_List');
    
      // Generate Excel file and trigger download
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    
      saveAs(blob, 'All_Transactions_List.xlsx');
    };
    

    const handleBack = () => {
        navigate(`/${secretKey}/Cards`);
      };
  
    return (
        <>
         
            { data? (
                   <Fragment>
			{/* <div className="form-head mb-4">
				<h2 className="text-black font-w600 mb-0">Load Requests</h2>
			</div> */}
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">Transactions <br/><br/>
                    <h5>Total Requests: {count}</h5></h4>

                    <button className="btn btn-primary" onClick={exportToExcel}>Export to Excel</button>
                    <h5> Card Balance:  {balance} USD</h5>
                   
                    <button className="btn btn-secondary" type="button" onClick={handleBack}>Back to list</button>

                </div>

                <div className="card-body">
                    <div className="table-responsive">
                        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
                        <table {...getTableProps()} className="table filtering-table table-responsive-lg">
                            <thead>
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <th {...column.getHeaderProps()}>
                                                {column.render('Header')}
                                                {column.canFilter ? column.render('Filter') : null}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()} className="">
                                {page.map((row) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map((cell) => {
                                                return <td {...cell.getCellProps()}> {cell.render('Cell')} </td>;
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <div className="d-flex justify-content-between">
                            <span>
                                Page{' '}
                                <strong>
                                    {pageIndex + 1} of {totalPages}
                                </strong>{''}
                            </span>
                            <span className="table-index">
                                Go to page : {' '}
                                <input
                                    type="number"
                                    className="ms-2"
                                    defaultValue={pageIndex + 1}
                                    onChange={(e) => {
                                        const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
                                        handleGotoPage(pageNumber);
                                    }}
                                />
                            </span>
                        </div>
                        <div className="text-center">
                            <div className="filter-pagination mt-3">
                                <button className="previous-button" onClick={() => handleGotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>
                                <button className="previous-button" onClick={handlePreviousPage} disabled={!canPreviousPage}>
                                    Previous
                                </button>
                                <button className="next-button" onClick={handleNextPage} disabled={!canNextPage}>
                                    Next
                                </button>
                                <button className="next-button" onClick={() => handleGotoPage(totalPages - 1)} disabled={!canNextPage}>{'>>'}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>			
            </Fragment>

            ):(<div>ffff</div>)
        }
        </>
    );
}

export default TransactionDetail;
