import axios from 'axios';
import {  API_URL } from '../config/env';


const getToken = () => {
    const tokenHeader = localStorage.getItem('Header');
    const tokenPayload = localStorage.getItem('payload');
    const tokenSignature = localStorage.getItem('sig');

    return `${tokenHeader}.${tokenPayload}.${tokenSignature}`;
};
export const EventService = {

    addEvent:(data) =>{
        const token = getToken();
        return axios.post(`${API_URL}/admin/events/add`, data, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },

    getEvents:(page) =>{
        const token = getToken();
        return axios.get(`${API_URL}/admin/events?page=${page}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    },

    editEvent:(data,id) =>{
        const token = getToken();
        return axios.post(`${API_URL}/admin/events/edit/${id}`, data , {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    },
    getById: (Id) => {
        const token = getToken();
        return axios.get(`${API_URL}/admin/events/${Id}`, {
            headers: {
                Authorization: `Bearer ${token}`, // Set the Authorization header
            },
        });
    },
    statusEvent:(data,id) =>{
        const token = getToken();
        return axios.post(`${API_URL}/admin/events/active/${id}`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    },

    getActivityType:() =>{
        const token = getToken();
        return axios.get(`${API_URL}/admin/activity/type`, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },

    getRewardType:() =>{
        const token = getToken();
        return axios.get(`${API_URL}/admin/reward/type`, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },

    changeEventStatus:(data) =>{
        const token = getToken();
        return axios.post(`${API_URL}/admin/events/active`,data, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },
    updateEvent:() =>{
        const token = getToken();
        return axios.post(`${API_URL}/admin/rewards/rewardUsedTimeOuT`, {}, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },

};