import {format} from 'date-fns';
import { ColumnFilter } from './ColumnFilter';
import { useNavigate } from 'react-router-dom';
import {secretKey} from '../../../config/env';


export const COLUMNS = [
	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'id',
		Filter: ColumnFilter,
		//disableFilters: true,
	},
	{
		Header: 'Full Name',
		Footer: 'Full Name',
		accessor: (row) => ` ${row.client.prenom} ${row.client.nom}`, // Combine first and last names
		id: 'clientName', // Set an id to prevent accessor conflicts
		Filter: ColumnFilter,
	},
	{
		Header : 'username',
		Footer : 'username',
		accessor: 'client.email',
		Filter: ColumnFilter,
	},

	{
		Header: 'Status',
		Footer: 'Status',
		accessor: 'suspendStatus',
		Filter: ColumnFilter,
		Cell: ({ value }) => {
			const isActive = value === false;
			const badgeClass = isActive ? 'badge-success' : 'badge-danger';
			const displayText = isActive ? 'Active' : 'Suspended';
	
			return (
				<span className={`badge ${badgeClass}`}>
					{displayText}
				</span>
			);
		},
	},
	
	
    {
        Header: 'Action',
        Footer: 'Action',
        accessor: 'Id', // Assuming `id` is the unique identifier for the row
        Cell: ({ row }) => {
            const navigate = useNavigate();
            const handleShow = () => {
                navigate(`/${secretKey}/client-card-details/${row.original.id}`);
            };

            return (
                <div>
                    <button className="btn btn-primary" onClick={handleShow}>Show</button>
                </div>
            );
        },
        Filter: ColumnFilter,
    },
    {
        Header: 'Transaction',
        Footer: 'Transaction',
        accessor: 'cardId', // Assuming `id` is the unique identifier for the row
        Cell: ({ row }) => {
            const navigate = useNavigate();
            const handleShow = () => {
                navigate(`/${secretKey}/transaction/${row.original.id}`);
            };

            return (
                <div>
                    <button className="btn btn-secondary" onClick={handleShow}>View</button>
                </div>
            );
        },
        Filter: ColumnFilter,
    },
]



export const GROUPED_COLUMNS = [
	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'id'
	},
	{
		Header : 'Name',
		Footer : 'Name',
		columns: [
			{
				Header : 'First Name',
				Footer : 'First Name',
				accessor: 'first_name'
			},
			{
				Header : 'Last Name',
				Footer : 'Last Name',
				accessor: 'last_name'
			},
		]
	},
	{
		Header: 'Info',
		Footer: 'Info',
		columns: [
			{
				Header : 'Date of  Birth',
				Footer : 'Date of  Birth',
				accessor: 'date_of_birth'
			},
			{
				Header : 'Country',
				Footer : 'Country',
				accessor: 'country',
			},
			{
				Header : 'Phone',
				Footer : 'Phone',
				accessor: 'phone'
			},
		]
	},
]