import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
import LogoutPage from './Logout';
/// Image
import profile from "../../../images/profile/17.jpg";
import { Dropdown } from "react-bootstrap";
import { UserService } from "../../../services/UserService";
import {secretKey} from '../../../config/env';

const Header = () => {
    const [data, setData] = useState([]); // State to store the fetched data
    const [count, setCount] = useState([]); 

    useEffect(() => {
		UserService.getAllN(1) // API expects 1-based index, so add 1
		.then((response) => {
			const { data } = response.data;
			setData(data.items); // Set the data received from API
			setCount(data.totalCount);
		})
		.catch((error) => {
			console.error('Error fetching data:', error);
		});
	}, []);
	
  return (
    <div className="header">
		<div className="header-content">
			<nav className="navbar navbar-expand">
				<div className="collapse navbar-collapse justify-content-between">	
					<div className="header-left">
						<div className="dashboard_bar">
							<div className="input-group search-area d-lg-inline-flex d-none">
							</div>
						</div>
					</div>

					<ul className="navbar-nav header-right">

						<Dropdown as="li" className="nav-item  notification_dropdown">
							<Dropdown.Toggle variant=""  className="nav-link  ai-icon i-false" >
								<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path fill-rule="evenodd" clip-rule="evenodd" d="M12.6001 4.3008V1.4C12.6001 0.627199 13.2273 0 14.0001 0C14.7715 0 15.4001 0.627199 15.4001 1.4V4.3008C17.4805 4.6004 19.4251 5.56639 20.9287 7.06999C22.7669 8.90819 23.8001 11.4016 23.8001 14V19.2696L24.9327 21.5348C25.4745 22.6198 25.4171 23.9078 24.7787 24.9396C24.1417 25.9714 23.0147 26.6 21.8023 26.6H15.4001C15.4001 27.3728 14.7715 28 14.0001 28C13.2273 28 12.6001 27.3728 12.6001 26.6H6.19791C4.98411 26.6 3.85714 25.9714 3.22014 24.9396C2.58174 23.9078 2.52433 22.6198 3.06753 21.5348L4.20011 19.2696V14C4.20011 11.4016 5.23194 8.90819 7.07013 7.06999C8.57513 5.56639 10.5183 4.6004 12.6001 4.3008ZM14.0001 6.99998C12.1423 6.99998 10.3629 7.73779 9.04973 9.05099C7.73653 10.3628 7.00011 12.1436 7.00011 14V19.6C7.00011 19.817 6.94833 20.0312 6.85173 20.2258C6.85173 20.2258 6.22871 21.4718 5.57072 22.7864C5.46292 23.0034 5.47412 23.2624 5.60152 23.4682C5.72892 23.674 5.95431 23.8 6.19791 23.8H21.8023C22.0445 23.8 22.2699 23.674 22.3973 23.4682C22.5247 23.2624 22.5359 23.0034 22.4281 22.7864C21.7701 21.4718 21.1471 20.2258 21.1471 20.2258C21.0505 20.0312 21.0001 19.817 21.0001 19.6V14C21.0001 12.1436 20.2623 10.3628 18.9491 9.05099C17.6359 7.73779 15.8565 6.99998 14.0001 6.99998Z" fill="#3E4954"/>
								</svg>
								<span className="badge light text-white bg-primary rounded-circle">{count}</span>
							</Dropdown.Toggle>
							<Dropdown.Menu align="end" className="mt-2">
							  <PerfectScrollbar className="widget-media dz-scroll p-3 height380 ps">
								<ul className="timeline">
								{data.map((data, index) => (
                                            <li key={index}>
                                                <div className="timeline-panel">
                                                    <div className="media-body">
                                                        <h6 className="mb-1">{data.message}</h6>
                                                        <small className="d-block">{data.user.email}</small>
														<small className="d-block">{data.createdAt.date.substring(0, 10)}</small>

                                                    </div>
                                                </div>
                                            </li>
                                        ))}
								</ul>
								<div className="ps__rail-x" style={{ left: 0, bottom: 0 }}>
								  <div
									className="ps__thumb-x"
									tabIndex={0}
									style={{ left: 0, width: 0 }}
								  />
								</div>
								<div className="ps__rail-y" style={{ top: 0, right: 0 }}>
								  <div
									className="ps__thumb-y"
									tabIndex={0}
									style={{ top: 0, height: 0 }}
								  />
								</div>
							  </PerfectScrollbar>
							  <Link className="all-notification" to={`/${secretKey}/notifications`}>
								See all notifications <i className="ti-arrow-right" />
							  </Link>
							</Dropdown.Menu>
						</Dropdown>
						<Dropdown as="li" className="nav-item header-profile ">
							<Dropdown.Toggle
							  as="a"
							  to="#"
							  variant=""
							  className="nav-link i-false c-pointer"
							>
								<div className="header-info">
									<span className="text-black">Hello,<strong></strong></span>
									<p className="fs-12 mb-0">Super Admin</p>
								</div>
								<img src={profile} width={20} alt="" />
							</Dropdown.Toggle>
							<Dropdown.Menu align="end" className="mt-2">
							  <Link to={`/${secretKey}/app-profile`} className="dropdown-item ai-icon">
								<svg
								  id="icon-user1"
								  xmlns="http://www.w3.org/2000/svg"
								  className="text-primary"
								  width={18}
								  height={18}
								  viewBox="0 0 24 24"
								  fill="none"
								  stroke="currentColor"
								  strokeWidth={2}
								  strokeLinecap="round"
								  strokeLinejoin="round"
								>
								  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
								  <circle cx={12} cy={7} r={4} />
								</svg>
								<span className="ms-2">Profile </span>
							  </Link>
							  <LogoutPage />
							</Dropdown.Menu>
						</Dropdown>
					</ul>
				</div>
			</nav>
		</div>
    </div>
  );
};

export default Header;
