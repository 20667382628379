import React, { useEffect, useRef, useState } from "react";
import QRCodeStyling from "qr-code-styling";
import { UserService } from "../../../services/UserService";

const QrCode = () => {
  const [data, setData] = useState(null);
  const qrCodeRef = useRef(null);
  const qrCodeInstance = useRef(null);

  // Fetch data from API
  useEffect(() => {
    UserService.getQrCode()
      .then((response) => {
        const { data } = response.data;
        setData(data); // Assuming `data` contains the secret key
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  // Initialize and update QRCodeStyling
  useEffect(() => {
    if (!data) return;

    if (!qrCodeInstance.current) {
      qrCodeInstance.current = new QRCodeStyling({
        width: 300,
        height: 300,
        data: data.items, // Use the appropriate property for QR code data
        dotsOptions: {
          gradient: {
            type: "radial",
            colorStops: [
              { offset: 0, color: "#000000" },
              { offset: 1, color: "#000000" },
            ],
          },
          type: "rounded",
        },
        backgroundOptions: {
          gradient: {
            type: "radial",
            colorStops: [
              { offset: 0, color: "#ffffff" },
              { offset: 1, color: "#ffffff" },
            ],
          },
        },
        imageOptions: {
          margin: 10,
          scale: 0.4,
        },
        cornersSquareOptions: {
          color: "#000000",
          type: "extra-rounded",
        },
        cornersDotOptions: {
          color: "#000000",
          type: "rounded",
        },
      });

      if (qrCodeRef.current) {
        qrCodeInstance.current.append(qrCodeRef.current);
      }
    } else {
      qrCodeInstance.current.update({
        data: data.qrCodeUrl, // Update QR code data dynamically
      });
    }
  }, [data]);

  return (
    <div>
      <div ref={qrCodeRef} />
      {data && (
        <div>
          <p>
            **Secret Key**: <strong>{data.secret}</strong>
          </p>
        </div>
      )}
    </div>
  );
};

export default QrCode;
