import React, { useState, Fragment, useEffect } from 'react';
import swal from "sweetalert";
import { EventService } from '../../../services/EventService';
import { Stepper, Step } from 'react-form-stepper';

import StepOne from "./StepOne";
import StepTwo from "./StepTwo";

import moment from 'moment';
import {secretKey} from '../../../config/env';

const AddEvent = () => {
    const [formData, setFormData] = useState({
        eventName: "", // Initialize fields with default values
        eventAcronym: "",
        startDate: "", // Initialize empty or default date
        endDate: "",
        rewardNumber: ""
    });// Initialize form data with the passed data
    const [activities, setActivities] = useState([]); // All activities from the API
    const [selectedActivities, setSelectedActivities] = useState([]); // Selected activities (to show as tags)
	const [goSteps, setGoSteps] = useState(0);
	const [signup, setSignup] = useState(1);

    const [cardCreation, setCardCreation] = useState(0);

    const [selectedRewardType, setSelectedRewardType] = useState([]); // Selected activities (to show as tags)
    const [selectedReward, setSelectedReward] = useState('');

    const [rewardConditions, setRewardConditions] = useState({
        Deposit: 0,
        Topup: 0,
        Transaction: 0,
        Reward:0
    });
    const [RewardType, setRewardType] = useState([]); // Selected activities (to show as tags)

    // Fetch activity types when component is mounted
    useEffect(() => {
        EventService.getActivityType()
            .then((response) => {
                const { data } = response.data;
                setActivities(data.items);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    useEffect(() => {
        EventService.getRewardType()
            .then((response) => {
                const { data } = response.data;
                setRewardType(data);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);
    // Handle input change for other fields

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'startDate' || name === 'endDate') {
            // Format the date as 'YYYY-MM-DD'
            const formattedDate = moment(value).format('YYYY-MM-DD');
    
            setFormData(prev => {
                const newData = { ...prev, [name]: formattedDate };
    
                // If endDate is being updated, ensure it's greater than startDate
                if (name === 'endDate' && formattedDate <= prev.startDate) {
                    // Reset endDate if it's less than or equal to startDate
                }
    
                return newData;
            });
        } else {
            // For other fields, just update as normal
            setFormData(prev => ({ ...prev, [name]: value }));
        }
    };

    // Handle adding selected activity to the list
    // const handleActivitySelect = (e) => {
    //     const selectedActivityId = e.target.value;
    //     if (selectedActivityId && !selectedActivities.includes(selectedActivityId)) {
    //         setSelectedActivities([...selectedActivities, selectedActivityId]);
    //     }
        
    // };
    const handleActivitySelect = (e) => {
        const selectedActivityId = e.target.value;
        
        if (selectedActivityId && !selectedActivities.includes(selectedActivityId)) {
            // Update selected activities
            setSelectedActivities((prevSelectedActivities) => {
                const updatedActivities = [...prevSelectedActivities, selectedActivityId];
                
                // Check if '4' is in the updated selected activities, set cardCreation to 1
                if (updatedActivities.includes("4")) {
                    setCardCreation(1);
                }
                // Add condition for "1" (Signup) or any other specific activity
                else if (updatedActivities.includes("1")) {
                    setCardCreation(0); // Or some other logic if needed for "1"
                }
                
                return updatedActivities;
            });
        }
    
    

        
    };
    
    const handleCardCreationChange = (e) => {
        const value = Number(e.target.value); // Convert the value to a number
        // Update cardCreation value
        setCardCreation(value);
    
        // Check if 'selectedActivities' includes "4", then set cardCreation to 1 if needed
        if (selectedActivities.includes("4")) {
            setCardCreation(1); // Set cardCreation value to 1 if "4" is selected
        }
    };
    
    // const handleRewardSelect = (e) => {
    //     const selectedRewardId = e.target.value;
    //     if (selectedRewardId && !selectedRewardType.includes(selectedRewardId)) {
    //         setSelectedRewardType([...selectedRewardType, selectedRewardId]);
    //     }
    // };

    // Handle removing selected activity from the list (remove tag and add it back to the dropdown)
    // const removeActivity = (activityToRemove) => {
    //     setSelectedActivities(selectedActivities.filter(activity => activity !== activityToRemove));
    // };
    // const removeActivity = (activityToRemove) => {
    //     // Remove the activity from the selected list
    //     setSelectedActivities((prevActivities) =>
    //         prevActivities.filter((activity) => activity !== activityToRemove)
    //     );
    //     console.log(selectedActivities);
    //     console.log(rewardConditions);
    //     // Clear any form data associated with the removed activity
    //     setRewardConditions((prevFormData) => {
    //         const updatedFormData = { ...prevFormData };
    //         delete updatedFormData[activityToRemove]; // Assuming the form data is keyed by activity
    //         return updatedFormData;
    //     });
    // };


    // const removeActivity = (activityToRemove) => {
    //     // Update selected activities
    //     setSelectedActivities((prevActivities) => {
    //         const updatedActivities = prevActivities.filter((activity) => activity !== activityToRemove);
    //         return updatedActivities; // Return the updated activities list
    //     });
    
    //     // Clear the corresponding form data for the removed activity
    //     setRewardConditions((prevFormData) => {
    //         const updatedFormData = { ...prevFormData };
    
    //         // Dynamically clear the value based on `activityToRemove`
    //         if (activityToRemove === 2) {
    //             updatedFormData.Deposit = ''; // Clear Deposit value
    //         } else if (activityToRemove === 3) {
    //             updatedFormData.Topup = ''; // Clear Topup value
    //         } else if (activityToRemove === 5) {
    //             updatedFormData.Transaction = ''; // Clear Transaction value
    //         } else if (activityToRemove === "Reward") {
    //             updatedFormData.Reward = ''; // Clear Reward value
    //         }
    
    //         return updatedFormData; // Return updated form data
    //     });
    // };
    const removeActivity = (activityToRemove) => {
        // Update selected activities
        setSelectedActivities((prevActivities) => {
            const updatedActivities = prevActivities.filter((activity) => activity !== activityToRemove);
            return updatedActivities; // Return the filtered list
        });
    
        // Clear the corresponding form data for the removed activity
        setRewardConditions((prevFormData) => {
            const updatedFormData = { ...prevFormData };


            // Dynamically clear the value for the removed activity
            const activityToRemoveNumber = Number(activityToRemove); // Ensure it's a number

            switch (activityToRemoveNumber) {
                
                case 2:
                    updatedFormData.Deposit = 0; // Clear Deposit value
                    break;
                case 3:
                    updatedFormData.Topup = 0; // Clear the field
                    break;
                case 4:
                    setCardCreation(0); // Clear the field
                    break;
                case 5:
                    updatedFormData.Transaction = 0; // Clear Transaction value
                    break;
                default:
                    console.warn(`Unknown activityToRemove: ${activityToRemove}`);
            }
    
            return updatedFormData; // Return updated form data
        });
    };
    
    // Monitor changes in the selected activities or form data
    useEffect(() => {

    }, [selectedActivities, rewardConditions,cardCreation]);
    
    
    
    const removeReward = (rewardToRemove) => {
        setSelectedRewardType(selectedRewardType.filter(reward => reward !== rewardToRemove));
    };
    // Handle change in reward amounts (Deposit, Topup, Transaction)
    const handleRewardAmountChange = (reward, value) => {
        setRewardConditions({
            ...rewardConditions,
            [reward]: Number(value) // Convert the value to a number
        });
    };

    const handleRewardSelect = (e) => {
        const selectedRewardId = e.target.value;
        setSelectedReward(selectedRewardId); // Update the selected reward ID
    };
    const selectedRewardObj = RewardType.find(reward => String(reward.id) === String(selectedReward)); // Convert to string to avoid type mismatch

    const handleSubmit = (e) => {
        e.preventDefault();
        const eventData = {
            eventName: formData.eventName,
            eventAcronym: formData.eventAcronym,
            startDate:formData.startDate,
            endDate:formData.endDate,
            rewardNumber:formData.rewardNumber,
            ActivityTypes: selectedActivities.map(activityId => {
                const activity = activities.find(a => String(a.id) === String(activityId));
                return activity ? activity.activityName : null;
            }).filter(name => name !== null),            
            rewardCondition: [
                {
                    rewardType: selectedRewardObj.Name,
                    rewardAmount: rewardConditions.Reward,  // Or make this dynamic if needed
                    rewardDescription: null,
                    requiredActivities: {
                        Signup: signup,
                        Topup: rewardConditions.Topup || 0,
                        Deposit: rewardConditions.Deposit|| 0,
                        Transaction: rewardConditions.Transaction || 0,
                        CardCreation: cardCreation,
                    },
                },
            ],
        };

        // Handle form submission (e.g., send `eventData` to API)
        const isConfirmed = window.confirm("Are you sure you want to add this event?");
        if(isConfirmed){
        EventService.addEvent(eventData)
        .then((response) => {
            swal("Good job!", "Event added successfully", "success")
            .then(() => {
                // Redirect to another page
                window.location.href = `/${secretKey}/events`; // Replace with your desired route
            });
        })
        .catch((error) => {
            const errorMessage = error.response?.message || 'An unexpected error occurred';
            swal("Ooops!", errorMessage, "error");
        });
        }
    };
    const validateForm = () => {
        // Check if start date and end date are valid, and end date is later than start date
        return selectedActivities && selectedActivities.length > 0 && formData.eventName && formData.eventAcronym && formData.rewardNumber && formData.startDate && formData.endDate && formData.endDate > formData.startDate;
    };
    const validateForm2 = () => {
        // Ensure conditions for selectedActivities
        if (selectedActivities.includes("3") && selectedActivities.includes("2")  &&  selectedActivities.includes("1")) {
            // Both activities 2 and 3 are selected
            return selectedRewardObj && 
                   rewardConditions.Reward && 
                   rewardConditions.Topup && 
                   rewardConditions.Deposit && signup;
        } else if (selectedActivities.includes("2")) {
            // Only activity 2 is selected
            return selectedRewardObj && 
                   rewardConditions.Reward && 
                   rewardConditions.Deposit;
        } else if (selectedActivities.includes("3")) {
            // Only activity 3 is selected
            return selectedRewardObj && 
                   rewardConditions.Reward && 
                   rewardConditions.Topup;
        }
        else if (selectedActivities.includes("1")) {
            // Only activity 3 is selected
            return selectedRewardObj && 
                   rewardConditions.Reward && 
                   signup;
        }
        // Default case: No valid activity selected
        return false;
    };
    
    
    // Handle the next button click
    const handleNextClick = () => {
        if (validateForm()) {
            setGoSteps(1); // Proceed to the next step if validation is successful
        } else {
            // Optionally, show a validation message or highlight the fields
            alert('Please ensure the end date is later than the start date.');
        }
    };
    const handleNextClickstep2 = () => {
        if (validateForm2()) {
            setGoSteps(2); // Proceed to the next step if validation is successful
        }
    };

    
    return (
      
        <Fragment>
			<div className="row">
				<div className="col-xl-12 col-xxl-12">
					<div className="card">
						<div className="card-header">
							<h4 className="card-title">Add Event</h4>
						</div>
						<div className="card-body">
						
							<div className="form-wizard ">
								<Stepper className="nav-wizard" activeStep={goSteps} label={false}>
									<Step className="nav-link" onClick={() => setGoSteps(0)} />
									<Step className="nav-link" onClick={() => setGoSteps(1)} />
									<Step className="nav-link" onClick={() => setGoSteps(2)} />
								</Stepper>
                                <form onSubmit={handleSubmit}>

							  {goSteps === 0 && (
								<>
									<StepOne 
                                    formData={formData}
                                    handleInputChange={handleInputChange}
                                    handleActivitySelect={handleActivitySelect}
                                    activities={activities}
                                    selectedActivities={selectedActivities}
                                    rewardConditions={rewardConditions}
                                    removeActivity={removeActivity}
                                    
                                    />	
              
									<div className="text-end toolbar toolbar-bottom p-2">
                                    <button
                                    className="btn btn-primary sw-btn-next"
                                    onClick={handleNextClick} // Use custom click handler
                                    disabled={!validateForm()} // Disable button if form is not valid
                                >
                                        Next
                                        </button>
									</div>	
								</>
							  )}
							  {goSteps === 1 && (
								<>
									<StepTwo                 
                                    RewardType={RewardType}
                                    selectedRewardType={selectedRewardType}
                                    handleRewardSelect={handleRewardSelect}
                                    removeReward={removeReward}
                                    rewardConditions={rewardConditions}
                                    handleRewardAmountChange={handleRewardAmountChange}
                                    selectedActivities={selectedActivities}
                                    selectedReward={selectedReward}
                                    handleCardCreationChange={handleCardCreationChange}
                                    cardCreation={cardCreation}  
                                   />
									<div className="text-end toolbar toolbar-bottom p-2">
										<button  className="btn btn-secondary sw-btn-prev me-1" onClick={() => setGoSteps(0)}>Prev</button>
										<button className="btn btn-primary sw-btn-next ms-1"
                                                   onClick={handleNextClickstep2} // Use custom click handler
                                                   disabled={!validateForm2()}
                                            
                                            
                                            >Next</button>
									</div>	
								</>
							  )}
							  {goSteps === 2 && (
								<>
                                <section>
                                <h3>Submitted Event Data</h3>
                                <p><strong>Event Name:</strong> {formData.eventName}</p>
                                <p><strong>Event Acronym:</strong> {formData.eventAcronym}</p>
                                <p><strong>Reward Number:</strong> {formData.rewardNumber}</p>
                                <p>
                                <strong>Selected Activities : </strong> 
                                {selectedActivities.length > 0 && selectedActivities.map((activityId) => {
                                            const activity = activities.find(a => String(a.id) === String(activityId));
                                            return activity ? (
                                                <span
                                                    key={activityId}
                                                    className="badge badge-primary mr-2"
                                                >
                                                    {activity.activityName}
                                                </span>
                                            ) : null;
                                        })}

                                </p>
                              
                                <h4>Submitted Rewards</h4>

                                <p><strong>Selected Reward:</strong> {selectedRewardObj ? selectedRewardObj.Name : 'None'}</p>
                                <p><strong>Reward Amount: </strong>{rewardConditions.Reward} {selectedRewardObj.Name ==="WalletDeposit" ? 
                                
                                "USDT"
                                :"USD"
                                }
                                
                                
                                
                                </p>

                                <h4>Reward Conditions</h4>

                                <p><strong>Signup: </strong>{signup==1 && "yes"} </p>
                                {selectedActivities.includes("4") && (
                                <p><strong>Card Creation: </strong>{cardCreation ==1 && "yes"} </p>)}
                                {selectedActivities.includes("2") && (
                                <p><strong>Deposit Amount: </strong>{rewardConditions.Deposit} </p>)}
                                {selectedActivities.includes("3") && (
                                <p><strong>TopUp Amount: </strong>{rewardConditions.Topup}</p>)}

                                <button className="btn btn-secondary mt-3" onClick={() => setGoSteps(1)}>
                                    Edit
                                </button>
           
									<div className="text-end toolbar toolbar-bottom p-2">
										<button  className="btn btn-secondary sw-btn-prev me-1" onClick={() => setGoSteps(1)}>Prev</button>
										<button type="submit"  className="btn btn-primary sw-btn-next ms-1">Submit</button>
									</div>	
                                    </section>	
                                </>	
							  )}

							  </form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
    );
};

export default AddEvent;
